// banner style ehar
.banner-one-swiper--wrapper-area{
    position: relative;
}
.banner-main-wrapper-one{
    .pre-title{
        p{
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #FFFFFF;
            font-weight: 600;
            font-family: var(--font-primary);
            margin-bottom: 25px;
            span{
                padding: 2px 5px;
                display: inline-block;
                background: var(--color-primary);
                border-radius: 3px;
            }
        }
    }
    
    .title{
        color: #fff;
        font-weight: 700;
        font-size: 70px;
        line-height: 82px;
        margin-bottom: 30px;
        @media #{$smlg-device} {
            font-size: 45px;
            line-height: 60px;
        }
        @media #{$large-mobile} {
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 15px;
        }
    }
    p.disc{
        margin-bottom: 30px;
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
    }
}

.bg-breadcrumb{
    background-image: url(../images/banner/breadcrumb/01.jpg);
}


.banner-one-swiper--wrapper-area{
    .banner-main-wrapper-one{
        .pre-title{
            transition-delay: 0.5s;
            opacity: 0;
        }
        .title{
            transition-delay: 1s;
            opacity: 0;
        }
        p.disc{
            transition-delay: 1.5s;
            opacity: 0;
        }
        .button-area{
            transition-delay: 2s !important;
            opacity: 0;
        }
    }
    .banner-one-start{
        position: relative;
        z-index: 1;
        img.shape{
            position: absolute;
            z-index: -1;
            top: 0;
            height: 100%;
            left: 0;
        }
    }
    .swiper-slide.swiper-slide-active{
        .banner-shape-area{
            .shape-1{
                top: 0;
                left: 0;
                animation: slideInLeft 0.8s linear;
            }
            .shape-2{
                top: 0;
                left: 0;
                animation: slideInLeft 1s linear;
            }
        }
        .banner-main-wrapper-one{
            .pre-title{
                transition-delay: 0.5s;
                animation: slideInUp3 1s linear;
                animation-delay: 0.5s;
                display: block;
                opacity: 1;            
            }
            .title{
                animation: fadeInUp2 1s linear;
                animation-delay: 1s;
                opacity: 1;
            }
            p.disc{
                animation: fadeInUp2 1.5s linear;
                animation-delay: 1.5s;
                opacity: 1;
            }
            .button-area{
                animation: fadeInUp2 1.5s linear;
                animation-delay: 2s !important;
                opacity: 1;
            }
        }
    }
}

// banner swiper one button next prev
.banner-one-swiper--wrapper-area{
    .swiper-button-next,
    .swiper-button-prev{
        background-image: none;
        transition: .3s;
        opacity: 0;
        i{
            font-size: 25px;
            color: #fff;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border: 1px solid #dfe1ff69;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s;
        }
        &:hover{
            i{
                background: var(--color-primary);
                border: 1px solid transparent;
                transition: .3s;
                height: 50px;
                width: 50px;
            }
        }
    }
    .swiper-button-next{
        right: 150px;
        &:hover{
            right: 140px;
        }
    }
    .swiper-button-prev{
        left: 120px;
        &:hover{
            left: 100px;
        }
    }
    &:hover{
        .swiper-button-next,
        .swiper-button-prev{
            opacity: 1;
            i{
                height: 70px;
                width: 70px;
                &:hover{
                    height: 80px;
                    width: 80px;
                }
            }
        }
    }
}


.rts-banner-air-condition{
    background-image: url(../images/banner/05.jpg);
}

.banner-condition-wrapper{
    -webkit-clip-path: polygon(84% 0, 100% 48%, 83% 100%, 0 100%, 0 0);
    clip-path: polygon(84% 0, 100% 48%, 83% 100%, 0 100%, 0 0);
    background: linear-gradient(270deg, rgba(243, 119, 31, 0.493) 12.26%, rgba(214, 73, 26, 0.486) 100%);
    width: 760px;
    height: 496px;
    mix-blend-mode: normal;
    .inner{
        // -webkit-clip-path: polygon(84% 0, 100% 48%, 83% 100%, 0 100%, 0 0);
        // clip-path: polygon(84% 0, 100% 48%, 83% 100%, 0 100%, 0 0);
        background: linear-gradient(270deg, #f3791fa1 12.26%, #e9370abb 100%);
        // mix-blend-mode: multiply;
        width: 760px;
        height: 496px;
    }
}


.banner-condition-wrapper{
    .inner{
        position: relative;
        .content-wrapper{
            position: absolute;
            left: 70px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            .title{
                color: #fff;
                font-weight: 700;
                font-size: 60px;
                line-height: 74px;
                text-transform: uppercase;
            }
            a.rts-btn.btn-white{
                color: var(--color-primary);
                margin-top: 20px;
                &::before{
                    background: #fff;
                }
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}


// banner area start
.banner-cleaning-area{
    background-image: url(../images/banner/06.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 900px;
}

.main-cleaning-wrapper-main-swiper{
    position: relative;
    &::after{
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        background-image: url(../images/banner/shape/cleaning-bottom.png);
        background-repeat: no-repeat;
        height: 65px;
    }
}
.main-cleaning-wrapper-main-swiper{
    position: relative;
    .banner-cleaning-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span{
            color: #fff;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        .title{
            color: #fff;
            font-weight: 700;
            font-size: 80px;
            line-height: 86px;
            margin-bottom: 50px;
            margin-top: 15px;
        }
    }   
}


.banner-solar-energy-bg{
    background-image: url(../images/banner/07.png);
    height: 850px;
    display: flex;
    align-items: center;
    .banner-solar-energy-inner{
        display: flex;
        align-items: center;
        position: relative;
        .title{
            font-weight: 700;
            font-size: 60px;
            line-height: 70px;
            color: #fff;
            margin-bottom: 40px;
            @media #{$sm-layout} {
                font-size: 45px;
                line-height: 57px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 49px;
            }
        }
        p.disc{
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
        }
        .button-area{
            display: flex;
            align-items: center;
            gap: 30px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            .rts-btn.btn-primary{
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
            }
            .rts-btn.btn-primary.bg-w{
                color: #1F1F25;
                &:hover{
                    color: #fff;
                }
                &::before{
                    background: #fff;
                }
            }
        }
        .vedio-icone{
            position: absolute;
            top: -60px;
            left: 30px;
            .video-play-button::after{
                border: 2px solid transparent;
                background: #fff;
            }
            .video-play-button::before{
                border: 2px solid #ffffff3a;
            }
            .text{
                min-width: max-content;
                color: #fff;
                margin-top: 10px;
                margin-left: 60px;
                font-weight: 500;
                font-size: 22px;
                line-height: 29px;
                font-family: var(--font-primary);
            }
        }
    }
}


.solar-energy-home{
    background: #F6F7F9;
}


// banner tweo- solaric

.banner-two-main-wrapper-solaric{
    background-image: url(../images/banner/08.jpg);    
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 825px;
    &.two{
        background-image: url(../images/banner/13.jpg);   
    }
    &.three{
        background-image: url(../images/banner/14.jpg);   
    }
}


// banner solaric two main wrapper
.banner-solari-2-content{
    span{
        text-transform: uppercase;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.08em;
        &.pre{
            color: #fff;
        }
        span{
            background: var(--color-primary);
            padding: 2px 7px;
            border-radius: 2px;
        }
    }
    .banner-title{
        font-weight: 700;
        font-size: 60px;
        line-height: 70px;
        color: #fff;
        margin-top: 25px;
        @media #{$sm-layout} {
            font-size: 44px;
            line-height: 55px;
        }
        @media #{$large-mobile} {
            font-size: 36px;
            line-height: 45px;
        }
    }
    p.disc{
        margin-top: 25px;
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
    }
}
.header-two-solari.header-top {
    max-width: 1920px;
    margin: auto;
}
.banner-two-swiper-start{
    position: relative;
    max-width: 1920px;
    margin: auto;
    overflow: hidden;
    .mySwiper-banner-2{
        position: relative;
        .swiper-pagination-b2{
            text-align: left !important;
            display: inline;
            width: max-content;
            top: 40%;
            flex-direction: column;
            display: flex;
            left: 50px;
            align-items: center;
            position: absolute;
            z-index: 10;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$smlg-device} {
                display: none;
            }
            .swiper-pagination-bullet{
                background: #fff;
                opacity: 1;
                margin: 5px 0;
                height: 6px;
                width: 6px;
                cursor: pointer;
            }
            .swiper-pagination-bullet-active{
                background-image: url(../images/banner/shape/01.png);
                background-size: contain;
                height: 20px;
                width: 20px;
                background-repeat: no-repeat;
                background-color: transparent;
                cursor: pointer;
            }
        }
    }
}

.banner-shop-content-wrapper{
    p.disc{
        br{
            @media #{$large-mobile} {
                display: none;
            }
        }
    }
}

.button-solari-banner-area{
    display: flex;
    align-items: center;
    position: relative;
    @media #{$sm-layout} {
        
    }
    .vedio-icone .video-play-button{
        position: absolute;
        z-index: 2;
        /* top: 50%; */
        left: 36%;
        transform: translateX(-50%) translateY(-50%);
        box-sizing: content-box;
        display: block;
        width: 32px;
        height: 44px;
        border-radius: 50%;
        padding: 18px 20px 18px 28px;
        display: flex;
        @media #{$laptop-device} {
            left: 39%;
        }
        @media #{$smlg-device} {
            left: 51%;
        }
        @media #{$sm-layout} {
            left: 44%;
        }
        @media #{$large-mobile} {
            left: 30px;
            top: 108px;            
        }
        .text{
            min-width: max-content;
            margin-left: 56px;
            margin-top: 10px;
            color: #fff;
        }
    }
    .vedio-icone .video-play-button::after{
        background: #fff;
        border: transparent;
    }
    .vedio-icone .video-play-button::before{
        background: #ffffff62;
        border: transparent;
        border-color: #fff;
    }
}
.banner-two-main-wrapper-solaric{
    position: relative;
    z-index: 1;
    .water-text{
        position: absolute;
        left: 10%;
        top: 25%;
        z-index: -1;
        text-transform: uppercase;
        text-align: center;
        margin: auto;
        display: block;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
        font-size: 200px;
        font-weight: 700;
        font-family: var(--font-primary);
        margin-bottom: -30px;
        @media #{$sm-layout} {
            font-size: 100px;
            font-weight: 700;
        }
    }
}


.rts-banner-three-solari{
    position: relative;
    z-index: 1;
    .left-banner-content-area-two{
        padding-left: 290px;
        padding-top: 280px;
        @media #{$extra-device} {
            padding-left: 150px;
        }
        @media #{$laptop-device} {
            padding-left: 80px;
            padding-bottom: 150px;
            padding-top: 150px;
        }
        @media #{$smlg-device} {
            padding-left: 50px;
            padding-top: 150px;
            padding-bottom: 180px;
        }
        @media #{$md-layout} {
            padding-top: 100px;
        }
        @media #{$large-mobile} {
            padding-left: 15px;
            padding-top: 60px;
        }
        .shape-image-1{
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            
        }
        .pre-title{
            color: var(--color-primary);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            display: block;
            margin-bottom: 20px;
            margin-left: 60px;
            position: relative;
            &::after{
                position: absolute;
                content: '';
                background-image: url(../images/banner/shape/03.png);
                width: 48px;
                height: 7px;
                background-size: contain;
                background-repeat: no-repeat;
                left: -60px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .title{
            font-weight: 700;
            font-size: 70px;
            line-height: 80px;
            @media #{$smlg-device} {
                font-size: 56px;
                line-height: 70px;
            }
            @media #{$large-mobile} {
                font-size: 36px;
                line-height: 55px;
            }
        }
        p.disc{
            br{
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
        .baner-three-button-area{
            display: flex;
            align-items: center;
            gap: 30px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            .call-button{
                display: flex;
                align-items: center;
                gap: 14px;
                i{
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0px 10px 21px rgba(0, 0, 0, 0.08);
                    color: var(--color-primary);
                }
                .info{
                    span{
                        color: #74787C;
                        display: block;
                        margin-bottom: 6px;
                    }
                    a{
                        .title{
                            color: #1F1F25;
                            margin-bottom: 0;
                            transition: 0.3s;
                            font-size: 18px;
                            line-height: 27px;
                        }
                        &:hover{
                            .title{
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
    }
}



.separator-Top {
    border-top: 1px solid #ffffff10;
}


.service-solari-short-info{
    .call{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
        }
        span{
            margin-left: 20px;
        }
        p{
            margin: 0;
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            a{
                transition: .3s;
            }
        }
    }
}

.marquee {
    max-width: 1920px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    gap: 30px;
    position: absolute;
    left: 0;
    bottom: 20px;
    .marquee__item{
        display: inline-block;
        will-change: transform;
        animation: marquee 120s linear infinite;
        .banner-marque-bottom{
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            height: 235px;
            p{
                display: block;
                margin: 0;
                text-align: center;
                margin: auto;
                display: block;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #E6E6E6;
                font-weight: 700;
                font-size: 200px;
                line-height: 100px;
                font-family: var(--font-primary);
                font-family: var(--font-primary);
                @media #{$laptop-device} {
                    font-size: 150px;
                }
                @media #{$large-mobile} {
                    font-size: 100px;
                }
            }
        } 
    }
}

.rts-banner-three-solari{
    position: relative;
}

@keyframes marquee{
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-100%);
    }
}
.thumbnail-image-banner{
    position: relative;
    img{
        width: 100%;
    }
    .vedio-icone{
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        .video-play-button::after{
            background: #fff;
            border: transparent;
            height: 77px;
            width: 77px;
        }
        .video-play-button::before{
            background: rgba(217, 217, 217, 0.16);
            border: transparent;
        }
    }
}


// banner slide nstyle slide Up

.banner-two-swiper-start{
    .swiper-slide{
        .banner-two-main-wrapper-solaric{
            .banner-solari-2-content{
                span.pre{
                    transition-delay: 0.2s;
                    opacity: 0;
                }
                .banner-title{
                    transition-delay: .5s;
                    opacity: 0;
                }
                p.disc{
                    transition-delay: .8s;
                    opacity: 0;
                }
                .button-solari-banner-area{
                    transition-delay: 1s !important;
                    opacity: 0;
                }
            }
        }
        &.swiper-slide-active{
            .banner-two-main-wrapper-solaric{
                .banner-solari-2-content{
                    span.pre{
                        animation: slideInUp3 1s linear;
                        animation-delay: 0.2s;
                        display: block;
                        opacity: 1;
                    }
                    .banner-title{
                        animation: fadeInUp2 1s linear;
                        animation-delay: .5s;
                        opacity: 1;
                    }
                    p.disc{
                        animation: fadeInUp2 1s linear;
                        animation-delay: .8s;
                        opacity: 1;
                        br{
                            @media #{$sm-layout} {
                                display: none;
                            }
                        }
                    }
                    .button-solari-banner-area{
                        animation: fadeInUp2 1s linear;
                        animation-delay: 1s !important;
                        opacity: 1;
                    }
                }
            }
        }
    }
}


// banner area start
.banner_bg-four{
    background-image: url(../images/banner/15.jpg);
    height: 980px;
    &.two{
        background-image: url(../images/banner/17.jpg);
    }
    @media #{$md-layout} {
        height: 750px;
    }
    @media #{$sm-layout} {
        height: 650px;
    }
}

.banner-four-main-content-wrapper{
    position: relative;
    .title-right-shape{
        position: absolute;
        right: 26%;
        top: 59%;
    }
    .title-main{
        color: #fff;
        font-size: 110px;
        font-weight: 700;
        line-height: 120px;
        @media #{$smlg-device} {
            color: #fff;
            font-size: 80px;
            font-weight: 700;
            line-height: 100px;        
        }
        @media #{$md-layout} {
            color: #fff;
            font-size: 70px;
            font-weight: 700;
            line-height: 70px;
        }
        @media #{$sm-layout} {
            font-size: 60px;
            line-height: 70px;
        }
        @media #{$large-mobile} {
            font-size: 45px;
        }
    }
    p.disc{
        color: #fff;
        @media #{$sm-layout} {
            br{
                display: none;
            }
        }
    }
}

.banner-area-four-wrapper-swiper-main{
    .title-right-shape{
        .draw{
            top: -30px;
            position: absolute;
            left: -80px;
            z-index: 0;
        }
    }
}





// cta main wrapper star

.cta-main-wrapper-4{
    background: var(--color-primary);
    padding: 35px 0;
    .main-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
        .title{
            margin-bottom: 0;
            color: #fff;
            color: #FFF;
            font-size: 34px;
            font-weight: 700;
            line-height: 44px;
            @media #{$md-layout} {
                font-size: 26px;
                font-weight: 500;
            }
            @media #{$sm-layout} {
                font-size: 26px;
                font-weight: 500;
            }
            br{
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
        .rts-btn{
            color: #000;
            &::before{
                background: #fff;
            }
            &:hover{
                color: #fff;
            }
        }
    }
}

.banner_bg-four{
    background-size: cover;
    background-repeat: no-repeat;
}
.title-right-shape{
    @media #{$smlg-device} {
        display: none !important;
    }
}


.mb_dec-20{
    margin-bottom: -20px;
}
.mt-dec-5{
    margin-top: -5px;
}
.mt-dec-30{
    margin-top: -30px;
}
.mt-dec-20{
    margin-top: -20px;
}


.rts-banner-area-four.banner_bg-four{
    position: relative;    
    .banner-social-rotate{
        position: absolute;
        left: 80px;
        bottom: 220px;
        @media #{$laptop-device} {
            display: none;
        }
        @media #{$smlg-device} {
            display: none;
        }
        @media #{$md-layout} {
            display: none;
        }
        @media #{$sm-layout} {
            display: none;
        }
        a{
            i{
                color: #fff;
            }
        }
        &::after{
            position: absolute;
            left: 5px;
            bottom: -60px;
            height: 40px;
            background: #fff;
            content: '';
            width: 1px;
        }
        .social-wrapper-one-horizental{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    p.follow{
        transform: rotate(-90deg);
        position: absolute;
        bottom: -130px;
        left: -28px;
        min-width: max-content;
        color: #fff;
    }
}

.banner-area-four-wrapper-swiper-main{
    position: relative;
    display: block;
    .swiper-pagination{
        position: absolute;
        right: 40px;
        left: auto;
        width: max-content;
        top: 50%;
        transform: rotate(-90deg);
        height: max-content;
        align-items: center;
        justify-content: center;
        display: flex;
        @media #{$sm-layout} {
            display: none;
        }
        .swiper-pagination-bullet{
            background-color: #fff;
            opacity: 1;
            height: 6px;
            width: 6px;
            margin: 0px 10px;
        }

        .swiper-pagination-bullet-active{
            background-image: url(../images/banner/active.png);
            background-size: contain;
            height: 30px;
            background-color: transparent;
            width: 30px;
        }
    }
    .banner-four-main-content-wrapper{
        .title-right-shape{
            transition-delay: 0.7s;
            opacity: 0;
            display: block;
        }
        .title-main{
            transition-delay: .3s;
            opacity: 0;
            @media #{$smlg-device} {
                br{
                    display: none;
                }
            }
            img{
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
        p.disc{
            transition-delay: .8s;
            opacity: 0;
        }
        .button-area{
            transition-delay: 1s !important;
            opacity: 0;
            display: block;
        }
    }
    .swiper-slide-active{
        .banner-four-main-content-wrapper{
            .title-right-shape{
                animation: slideInUp3 1s linear;
                animation-delay: 0.7s;
                display: block;
                opacity: 1;
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
            .title-main{
                animation: fadeInUp2 1s linear;
                animation-delay: .3s;
                opacity: 1;
                img{
                    @media #{$md-layout} {
                        display: none;
                    }
                    @media #{$sm-layout} {
                        display: none;
                    }
                }
            }
            p.disc{
                animation: fadeInUp2 1s linear;
                animation-delay: .8s;
                opacity: 1;
            }
            .button-area{
                animation: fadeInUp2 1s linear;
                animation-delay: 1s !important;
                opacity: 1;
            }
        }
    }
}



.banner-shop-area{
    background-image: url(../images/banner/16.jpg);
    max-width: 1760px;
    margin: auto;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    &::after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        z-index: -1;
        opacity: 0;
        @media #{$large-mobile} {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            z-index: -1;
            opacity: 1;
        }
    }
}

.category-title-left-area{
    .title{
        font-size: 42px;
        @media #{$large-mobile} {
            font-size: 36px;
            line-height: 50px;        
        }
    }
}



.banner-shop-content-wrapper{
    .pre{
        color: var(--color-primary);
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }
    .title{
        font-weight: 700;
        line-height: 75px;
        text-transform: uppercase;
        font-size: 60px;
        margin-bottom: 12px;
        margin-top: 12px;
        @media #{$sm-layout} {
            font-weight: 700;
            line-height: 58px;
            text-transform: uppercase;
            font-size: 42px;
            margin-bottom: 18px;
            margin-top: 12px;
        }
        @media #{$large-mobile} {
            font-weight: 700;
            line-height: 51px;
            text-transform: uppercase;
            font-size: 28px;
            margin-bottom: 18px;
            margin-top: 12px;
        }
        span.ita{
            font-style: italic;
            font-weight: 500 !important;
            font-family: var(--font-secondary);
            text-transform: capitalize;
        }
    }
}


.banner-six-height{
    height: 960px;
    @media #{$md-layout} {
        height: 700px;
    }
    @media #{$sm-layout} {
        height: 700px;
    }
    @media #{$large-mobile} {
        height: 600px;
    }
}
.bg-image-solution{
    background-image: url(../images/banner/18.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.rts-banner-six-area{
    position: relative;
    video{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .circle-animation{
        position: absolute;
        right: 80px;
        bottom: 190px;
        z-index: 10;
        border-radius: 50%;
        border: 1px solid #adadad;
        @media #{$laptop-device} {
            bottom: 47%;
        }
        @media #{$smlg-device} {
            bottom: 47%;
        }
        @media #{$md-layout} {
            bottom: 53%;
        }
        @media #{$sm-layout} {
            bottom: 62%;
            right: 37%;
        }
        @media #{$large-mobile} {
            bottom: 58%;
            right: 29%;
        }
        .unique-circle-text{
            &::before{
                content: "";
                display: block;
                width: 128px;
                height: 128px;
                border: 1px solid rgba(245, 245, 245, 0);
                position: absolute;
                transform: scale(0.5);
                left: 0;
                top: 0;
                transform: translate(20px, 20px);
                border-radius: 100%;
            }
        }
        .uni-animation-spin{
            display: inline-flex;
            animation: spin 10s linear 0s infinite;
            fill: #fff;
            height: auto;
            max-width: 152px;
            right: 10%;
            transform-origin: center;
            text-transform: uppercase;
            padding: 7px;
        }
        i{
            position: absolute;
            color: #fff;
            left: 42%;
            top: 42%;
            font-size: 24px;
        }
    }
}



.banner-social-rotate{
    position: absolute;
    left: 80px;
    bottom: 220px;
    @media #{$md-layout} {
        display: none;
    }
    @media #{$sm-layout} {
        display: none;
    }
    .social-wrapper-one-horizental{
        display: flex;
        flex-direction: column;
        gap: 20px;
        a{
            i{
                color: #fff;
                transition: .3s;
            }
            &:hover{
                i{
                    color: var(--color-primary);
                    transform: scale(1.2);
                }
            }
        }
    
    }
    .follow{
        transform: rotate(-90deg);
        position: absolute;
        bottom: -130px;
        left: -28px;
        min-width: max-content;
        color: #fff;
    }
    &::after{
        position: absolute;
        left: 5px;
        bottom: -60px;
        height: 40px;
        background: #fff;
        content: "";
        width: 1px;
    }
}

.banner-six-inner-content-wrapper{
    .inner-content{
        position: absolute;
        bottom: 90px;
        @media #{$laptop-device} {
            left: 139px;
        }
        @media #{$smlg-device} {
            left: 160px;
        }
        @media #{$md-layout} {
            left: 50px;
        }
        @media #{$sm-layout} {
            left: 50px;
        }
        @media #{$large-mobile} {
            left: 20px;
        }
        .title{
            color: #fff;
            font-size: 120px;
            font-style: normal;
            font-weight: 800;
            line-height: 120px;
            @media #{$smlg-device} {
                font-size: 90px;
                line-height: 110px;
            }
            @media #{$md-layout} {
                font-size: 50px;
                line-height: 90px;
            }
            @media #{$sm-layout} {
                font-size: 50px;
                line-height: 90px;
            }
            @media #{$large-mobile} {
                font-size: 36px;
                line-height: 49px;
            }
        }
        .under-line-button{
            color: #4AAB3D;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            text-decoration-line: underline;
            margin-top: 40px;
            display: block;
        }
    }
}


.header-four.header--sticky.sticky{
    @media #{$md-layout} {
        padding: 20px 15px !important;
    }
    @media #{$sm-layout} {
        padding: 20px 15px !important;
    }
}



